import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import GravityFormForm from "gatsby-plugin-gravity-forms";
import parse from 'html-react-parser'
import Img from "gatsby-image"

// import Hero from 'components/pages/security/hero';
// import Privacy from 'components/pages/security/privacy';
// import Scalability from 'components/pages/security/scalability';
// import BookADemo from 'components/shared/book-a-demo';
import MainLayout from 'layouts/main';
import {GatsbyImage, getImage} from "gatsby-plugin-image";


export const fluidImage = graphql`
    fragment fluidImage on File {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
          original {
            width
          }
        }
    }
`;


const FigmaLanding = ({
                          data: {
                              wpPage: {seo, uri, acf: pData },
                              wpGfForm:wpGfForm,
                              imageOne:imageOne,
                              imageTwo:imageTwo,
                              imageThree:imageThree,
                              imageFour:imageFour,
                          },
                          pageContext,
                      }) => {

    const landingHero = pData.landingHero
    const heroImage = landingHero.heroImage.localFile.childImageSharp;
    const revenueImages = landingHero.revenueTeamImages
    const containerSections = pData.containerSection
    const iconSections = pData.iconSection
    uri = "https://headsupai.dreamhosters.com"+uri

    console.log(iconSections);

    return (
        <MainLayout seo={seo} pageContext={pageContext}>
            <section className="pb-[72px] xl:pb-32 header-offset">
                <div className="relative max-w-[1240px] 2xl:max-w-[1472px] mx-auto px-4 md:px-6 lg:px-10 xl:px-0">
                    <div
                        className="hero-area relative bg-dark  py-8 px-4 md:px-8 lg:py-16 lg:px-16 xl:pb-16 rounded-top">
                        <div className="">
                            <h3 className="hero-sub-title font-normal text-2xl xl:text-4xl 2xl:text-5xl   text-white text-center uppercase tracking-wide">{landingHero.topHeading}</h3>
                            <h1 className="hero-title font-extrabold text-4xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-8xl  text-white  text-center uppercase tracking-wide">{landingHero.midHeading}</h1>
                            <h4 className="hero-sub-title tracking-wide font-black text-2xl  md:text-3xl xl:text-4xl 2xl:text-5xl   text-white text-center uppercase tracking-wide"> {landingHero.bottomHeading}</h4>
                        </div>
                        <GatsbyImage
                            image={getImage(heroImage)}
                            alt={pData.landingHero.heroImage.altText}
                        />
                        <div className="text-center">
                            <a className="font-bold !leading-none whitespace-nowrap rounded-full outline-none transition-colors duration-200 text-sm xl:text-lg py-6 px-12 rounded-12xl text-white bg-danger hover:bg-primary-2 py-3 px-8 xl:py-5 xl:px-10"
                               href={landingHero.heroButton.url}>{landingHero.heroButton.title}</a>
                        </div>
                    </div>
                    <div className="bg-dark-2 text-center p-4 md:p-6 lg:p-8 xl:p-11 rounded-bottom">
                        <p className="pb-4 color-gray text-sm lg:text-base xl:text-xl leading-none font-semibold tracking-wide">{landingHero.revenueHeading}</p>
                        <div className="flex justify-center flex-wrap imagesWarpper">
                            {revenueImages
                                .filter(({ revenueTeamImageItem }) => Boolean(revenueTeamImageItem))
                                .map(({ altText, revenueTeamImageItem: { localFile }}) => (
                                    <GatsbyImage
                                        key={altText}
                                        imgClassName="revenue-images"
                                        image={getImage(localFile.childImageSharp)}
                                        alt="Revenue"
                                    />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <div className="lookping-sections">
                {containerSections.map(({ subTitle, title, description,sectionFooter, featuredImage: { localFile }}) => (
                    <section key={title} className="single-looping-section pb-[72px] xl:pb-32 px-4 md:px-6 lg:px-10 xl:px-0">
                        <div
                            className="grid items-start md:grid-cols-2 items-center md:gap-10 xl:gap-32 max-w-[1240px]  mx-auto">
                            <div className="order-1 md:order-2 mx-auto">
                                <GatsbyImage
                                    imgClassName="revenue-images"
                                    image={getImage(localFile.childImageSharp)}
                                    alt="Revenue"
                                />
                            </div>
                            <div className="mt-6 md:mt-0 order-1 text-center md:text-left  mt-6 md:mt-0">
                                <h4 className="containerSubTitle  pb-4 text-dark font-bold text-xl tracking-wide uppercase lg:text-xxl">{subTitle}</h4>
                                <h2 className="containerTitle text-xxl md:text-3xl lg:text-4xl xl:text-5xl pb-4 lg:pb-6 font-extrabold text-dark">{title}</h2>
                                <p className="containerDesc text-base md:text-xl lg:text-2xl font-normal text-black">{description}</p>
                                {
                                    sectionFooter ? (
                                        <div className="container-footer">
                                            {parse(`${sectionFooter}`)}
                                        </div>
                                    ) : (
                                        <div></div>
                                    )
                                }
                            </div>
                        </div>
                    </section>
                ))}
            </div>

            <section className="pb-[72px] xl:pb-32 px-4 md:px-6 lg:px-10 xl:px-0 pt-[72px] bg-gray-13 xl:pt-32">
                <div className="max-w-[1240px] 2xl:max-w-[1472px] mx-auto">
                    <h3 className="text-3xl lg:text-5xl xl:text-7xl font-black tracking-wide uppercase text-dark pb-12 xl:pb-28 text-center">{pData.iconHeading}</h3>
                    <div
                        className="grid items-start xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 items-center gap-10 xl:gap-16 mt-6 md:mt-0 order-1 text-center md:text-left  mt-6 md:mt-0 enterprise-items">
                        {iconSections.map(({ title, description, icon: { localFile }}) => (
                            <div key={title} className="text-center enterprise-item">
                                <div className='enterprise-item-icon-wrap'>
                                    <Img className="enterprise-item-icon" fluid={localFile.childImageSharp.fluid} alt=""  />
                                </div>
                                <div className='enterprise-item-content'>
                                    <h3 className="enterprise-item-title">{title}</h3>
                                    <p className="enterprise-item-des">{description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className="gravityFrom pb-[72px] xl:pb-32 px-4 md:px-6 lg:px-10 xl:px-0 pt-[72px] bg-gray-13 xl:pt-32">
                <div className="max-w-[1240px] 2xl:max-w-[1472px] mx-auto">
                    <h3 className="text-xxl md:text-3xl lg:text-4xl xl:text-5xl font-black tracking-wide uppercase text-dark pb-12 xl:pb-28 text-center">{wpGfForm.title}</h3>
                    <div className="items-center text-center">
                        <div className="gravidyFormMain">
                            {/*<GravityFormForm data={wpGfForm} />*/}
                            <iframe style={{'width': "100%", "height": "600px"}} src={uri} frameBorder="0"></iframe>
                        </div>
                    </div>
                </div>

                <div className='bg-images alaImages'>
                    <Img className="alaImages1" fluid={imageOne.childImageSharp.fluid} alt=""  />
                    <Img className="alaImages2" fluid={imageTwo.childImageSharp.fluid} alt=""  />
                    <Img className="alaImages3" fluid={imageThree.childImageSharp.fluid} alt=""  />
                    <Img className="alaImages4" fluid={imageFour.childImageSharp.fluid} alt=""  />
                </div>
            </section>
        </MainLayout>
    );
};

export default FigmaLanding;

export const query = graphql`
    query ($id: String,$formId: Int!) {
        imageOne: file(
             relativePath: { eq: "bg-middle.png" }
           ) {
             ...fluidImage
       }
       imageTwo: file(
             relativePath: { eq: "bg-bottom.png" }
           ) {
             ...fluidImage
       }
       imageThree: file(
             relativePath: { eq: "bg-right.png" }
           ) {
             ...fluidImage
       }
       imageFour: file(
             relativePath: { eq: "bg-left.png" }
           ) {
             ...fluidImage
       }
        wpGfForm(databaseId: {eq: $formId}) {
            ...GravityFormFields
        }
        wpPage(id: { eq: $id }) {
            title
            uri
            acf {
                landingHero {
                    topHeading
                    midHeading
                    heroImage {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(height: 412)
                            }
                        }
                    }
                    bottomHeading
                    heroButton{
                        title
                        url
                    }
                    revenueHeading
                    revenueTeamImages{
                        revenueTeamImageItem{
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(height: 52)
                                }
                            }
                        }
                    }
                }
                containerSection{
                    subTitle
                    title
                    description
                    featuredImage{
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(height: 646)
                            }
                        }
                    }
                    sectionFooter
                }
                iconSection{
                    title
                    icon{
                        altText
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 500) {
                                    ...GatsbyImageSharpFluid
                                  }
                                  original {
                                    width
                                  }
                            }
                        }
                    }
                    description
                }
                iconHeading
            }
            ...wpPageSeo
        }
    }
`;
